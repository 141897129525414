'use strict';

import axios from 'axios';
import humps from 'humps';
import _isFunction from 'lodash/isFunction';
import { blobToString } from './utils';

let pendingRequests = {};

const api = axios.create({
  baseURL: '',
});

api.interceptors.request.use(
  (config) => {
    let requestData = `${config.url}&${config.method}`;

    if (pendingRequests[requestData]) {
      pendingRequests[requestData].cancel('Duplicate request');
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    config.cancelToken = source.token;

    pendingRequests[requestData] = source;

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    let requestData = `${response.config.url}&${response.config.method}`;
    pendingRequests[requestData] && delete pendingRequests[requestData];
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('Duplicate request cancelled');
    } else {
      throw error;
    }
  }
);

// const urlPrefix = '/api/development';
const urlPrefix = '';

export const CANCEL_REQUEST_MESSAGE = 'Request canceled by user';

// todo change to normal logic
if (!window.axiosInstanceMap) {
  window.axiosInstanceMap = new Map(); //key:(url+params), value: cancelRequest
}

export const deleteRequest = (key) => {
  if (window.axiosInstanceMap.has(key)) {
    window.axiosInstanceMap.get(key)(CANCEL_REQUEST_MESSAGE);
    window.axiosInstanceMap.delete(key);
  }
};

const setRequest = (canceledKey, source) => {
  deleteRequest(canceledKey);
  window.axiosInstanceMap.set(canceledKey, source);
};

/**
 * Helper method for executing a GET XHR request. useful because it wraps the
 * error and success callbacks to give easier access to the message.
 */
export const getJSON = (
  url,
  params,
  successCallback,
  errorCallback,
  convertCase = true,
  responseType = 'json',
  canceledKey
) => {
  // Shift arguments if `params` was omitted.
  if (_isFunction(params)) {
    errorCallback = successCallback;
    successCallback = params;
    params = undefined;
  }

  const decamelizeKeys = convertCase ? humps.decamelizeKeys : (o) => o;
  const camelizeKeys = convertCase ? humps.camelizeKeys : (o) => o;

  const source = axios.CancelToken.source();

  if (canceledKey) {
    setRequest(canceledKey, source.cancel);
  }

  return api
    .request({
      url: urlPrefix + url,
      method: 'get',
      params: decamelizeKeys(params || {}),
      responseType: responseType,
      cancelToken: source.token,
    })
    .then(
      (response) => {
        if (successCallback) {
          response && successCallback(camelizeKeys(response.data));
        }
      },
      async (error) => {
        if (errorCallback) {
          let response = null;

          try {
            response = camelizeKeys(error.response.data);

            // if we use the blob request type, we will get an error in blob, we need make json
            if (responseType === 'blob') {
              const stringResponse = await blobToString(response);
              response = JSON.parse(stringResponse);
            }
          } catch (e) {
            // If there's an error parsing the response text, then we can safely
            // swallow it silently here.
          }

          if (!response || !response['message']) {
            response = {
              message: 'An unexpected error has occurred. Please try again later.',
            };
          }

          if (error.message && error.message === CANCEL_REQUEST_MESSAGE) {
            response = {
              message: error.message,
            };
          }

          errorCallback(response);
        }
      }
    );
};

export const postJSON = (
  url,
  params,
  successCallback,
  errorCallback,
  convertCase,
  responseType,
  canceledKey
) => {
  return ajaxJSON(
    url,
    'post',
    params,
    successCallback,
    errorCallback,
    convertCase,
    responseType,
    canceledKey
  );
};

export const deleteJSON = (
  url,
  params,
  successCallback,
  errorCallback,
  convertCase,
  responseType,
  canceledKey
) => {
  return ajaxJSON(
    url,
    'delete',
    params,
    successCallback,
    errorCallback,
    convertCase,
    responseType,
    canceledKey
  );
};

export const putJSON = (
  url,
  params,
  successCallback,
  errorCallback,
  convertCase,
  responseType,
  canceledKey
) => {
  return ajaxJSON(
    url,
    'put',
    params,
    successCallback,
    errorCallback,
    convertCase,
    responseType,
    canceledKey
  );
};

export const patchJSON = (
  url,
  params,
  successCallback,
  errorCallback,
  convertCase,
  responseType,
  canceledKey
) => {
  return ajaxJSON(
    url,
    'patch',
    params,
    successCallback,
    errorCallback,
    convertCase,
    responseType,
    canceledKey
  );
};

/**
 * Helper methods for sending an AJAX JSON request.
 */
const ajaxJSON = (
  url,
  method,
  params,
  successCallback,
  errorCallback,
  convertCase = true,
  responseType = 'json',
  canceledKey
) => {
  params = params || {};

  const decamelizeKeys = convertCase ? humps.decamelizeKeys : (o) => o;
  const camelizeKeys = convertCase ? humps.camelizeKeys : (o) => o;

  const source = axios.CancelToken.source();

  if (canceledKey) {
    setRequest(canceledKey, source.cancel);
  }

  return api
    .request({
      url: urlPrefix + url,
      method: method,
      data: decamelizeKeys(params || {}),
      responseType: responseType,
      cancelToken: source.token,
    })
    .then(
      (response) => {
        return successCallback && successCallback(camelizeKeys(response.data));
      },
      async (error) => {
        if (errorCallback) {
          let response = null;

          try {
            response = camelizeKeys(error.response.data);

            // if we use the blob request type, we will get an error in blob, we need make json
            if (responseType === 'blob') {
              const stringResponse = await blobToString(response);
              response = JSON.parse(stringResponse);
            }
          } catch (e) {
            // If there's an error parsing the response text, then we can safely
            // swallow it silently here.
          }

          if (!response || !response['message']) {
            response = {
              message: 'An unexpected error has occurred. Please try again later.',
            };
          }

          if (error.message && error.message === CANCEL_REQUEST_MESSAGE) {
            response = {
              message: error.message,
            };
          }

          return errorCallback(response);
        }
      }
    );
};

/**
 * Helper methods for sending an AJAX JSON request.
 */
export const postFormData = (
  url,
  formData,
  successCallback,
  errorCallback,
  convertCase = true
) => {
  const decamelizeKeys = convertCase ? humps.decamelizeKeys : (o) => o;
  const camelizeKeys = convertCase ? humps.camelizeKeys : (o) => o;

  return api
    .request({
      url: urlPrefix + url,
      method: 'POST',
      data: formData,
    })
    .then(
      (response) => {
        return successCallback && successCallback(camelizeKeys(response.data));
      },
      async (error) => {
        if (errorCallback) {
          let response = null;

          try {
            response = camelizeKeys(error.response.data);
            // if we use the blob request type, we will get an error in blob, we need make json
            if (responseType === 'blob') {
              const stringResponse = await blobToString(response);
              response = JSON.parse(stringResponse);
            }
          } catch (e) {
            // If there's an error parsing the response text, then we can safely
            // swallow it silently here.
          }

          if (!response || !response['message']) {
            response = {
              message: 'An unexpected error has occurred. Please try again later.',
            };
          }

          return errorCallback(response);
        }
      }
    );
};
